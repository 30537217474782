import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/postcss",
  "date": "2015-12-31",
  "title": "POSTCSS",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "PostCSS is a tool for transforming CSS with JavaScript plugins. These plugins can support variables and mixins, transpile future CSS syntax, inline images, and more"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`PostCSS is can be a tool that refers itself as `}<inlineCode parentName="p">{`PostCSS`}</inlineCode>{` and also can be the ecosystem powerted by that tool. PostCSS the tool is a Node.js module that parses CSS into an abstract syntax tree (AST), passes that AST through any number of `}<inlineCode parentName="p">{`plugin`}</inlineCode>{` functions and then converts that AST back into a string, which you can output to a file.`}</p>
    <p><inlineCode parentName="p">{`Autoprefixer`}</inlineCode>{` is one of the most popular PostCSS plugins. PostCSS has over 200 plugins.`}</p>
    <h2>{`Why PostCSS?`}</h2>
    <p>{`Let's look at why PostCSS can become very useful to your day today workflow.`}</p>
    <ul>
  <li>PostCSS provides diverse functionality available via its plugin ecosystem.</li>
  <li>The option to use it with regular CSS</li>
  <li>Its modular</li>
  <li>Its rapid compilation time</li>
  <li>The ability to create libraries that don’t depend on one pre-processor</li>
  <li>The accessibility of creating your own plugins</li>
  <li>Its seamless deployment with many popular build tools</li>
    </ul>
    <Message type="warn" title="PostCSS is Neither a pre-processor nor a post-processor" content="Post processing or taking a finished stylesheet comprising valid CSS syntax and processing it, to do things like adding vendor prefixes. However PostCSS is not restricted to operating solely in this way. It can behave like a pre-processor too which makes it a processor." mdxType="Message" />
    <h2>{`Plugin Ecosystem`}</h2>
    <p>{`PostCSS plugins can vary from media queries to future syntax. Let's look at some of these categories.`}</p>
    <h2>{`Future CSS`}</h2>
    <h3><inlineCode parentName="h3">{`Autoprefixer`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`autoprefixer`}</strong>{` plugin adds vendor prefixes, using data from `}<a parentName="p" {...{
        "href": "http://caniuse.com"
      }}>{`Can I Use`}</a></p>
    <h3><inlineCode parentName="h3">{`CSSNext`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`cssnext`}</strong>{` plugin allows you to use future CSS features today.`}</p>
    <h2>{`Syntax Sugar`}</h2>
    <h3><inlineCode parentName="h3">{`PresCSS`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`precss`}</strong>{` tool contains plugins for Sass-like features like nesting or mixins.`}</p>
    <h3><inlineCode parentName="h3">{`Short`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`short`}</strong>{` plugin adds and extends numerous shorthand properties.`}</p>
    <h2>{`Images and Fonts`}</h2>
    <h3><inlineCode parentName="h3">{`PostCSS Assets`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`postcss-assets`}</strong>{` plugin inserts image dimensions and inlines files.`}</p>
    <h3><inlineCode parentName="h3">{`PostCSS Sprites`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`postcss-sprites`}</strong>{` plugin generates image sprites.`}</p>
    <h2>{`Linters`}</h2>
    <h3><inlineCode parentName="h3">{`Style Lint`}</inlineCode></h3>
    <p>{`The `}<strong parentName="p">{`stylelint`}</strong>{` plugin is a modular linter for CSS.`}</p>
    <h2>{`Speed`}</h2>
    <p>{`PostCSS is topping the test rankings when it comes to speed of loading. It runs on JavaScript and you only have to load the plugins you need.`}</p>
    <p>{`According to PostCSS, the results of such a test produced following results:`}</p>
    <pre><code parentName="pre" {...{}}>{`PostCSS:   40 ms
Rework:    75 ms   (1.9 times slower)
libsass:   76 ms   (1.9 times slower)
Less:      147 ms  (3.7 times slower)
Stylus:    166 ms  (4.1 times slower)
Stylecow:  258 ms  (6.4 times slower)
Ruby Sass: 1042 ms (26.0 times slower)
`}</code></pre>
    <h2>{`Create Your Own Plugins`}</h2>
    <p>{`Plugins for PostCSS are written in JavaScript, and as such anyone who can write JavaScript can create a plugin for any purpose they want.`}</p>
    <p>{`Preprocessor projects like Stylus, Sass and LESS are great but they can’t be everything to everyone. With PostCSS on the other hand, if you want a new feature, you can go ahead and make it.`}</p>
    <h2>{`Using PostCSS Plugins in Webpack`}</h2>
    <p>{`In your config file for Webpack, you can add a property for PostCSS as below:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{` module.exports = {
    module: {
        loaders: [
            {
                test:   /\\.css$/,
                loader: "style-loader!css-loader!postcss-loader"
            }
        ]
    },
    postcss: function () {
        return [require('autoprefixer'), require('precss')];
    }
  }
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`Working with PostCSS may convince you that CSS processing exists to solve problems; that pretty much all problems have multiple solutions; and that you might be qualified to pick between alternative solutions, or even construct your own.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      